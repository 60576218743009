.newpost-page{
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    margin-top: 40px;
}

.newpost-heading{
    font-family: 'metrophobic';
    font-size: 40px;
    font-weight: 800;

}

.newpost-inputs-container{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    /* height: 100%; */
    justify-content: space-evenly;
    /* align-items: flex-end; */
    /* margin-left: 200px; */
    width: 100%;
}


.newpost-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.title-input, .subtitle-input, .content-input, .category-input{
    outline: none;
    resize: none;
    border: 2px solid black;
    min-width: 1300px;
}

.title-input, .subtitle-input, .content-input, .category-input{
    /* min-height: 91px; */
    font-family: 'inika';
    font-size: 30px;
    padding: 10px;
}

.content-input{
    height: 1000px;
}

.category-input{
    font-size: 20px;
}



.newpost-limit{
    font-family: 'metrophobic';
    font-weight: 200;
    font-size: 20px;
}

.newpost-publish{
    width: 92%;
    display: flex;
    margin-top: 60px;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

.newpost-form-error{
    color: red;
    font-size: 20px;
    font-family: 'metrophobic';
    font-weight:900;
    width: 84%;
}