.article-page{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    margin-top: 80px;
    justify-content: center;
    
}

.article-left{
    display: flex;
    justify-content: center;
}

.article-right{
    padding-left: 20px;
    border-left: 1px solid black;
    display: flex;
    flex-direction: column;
    gap: 300px;
    position: sticky;
    height: 100vh;
    top: 20px;
   
}

.article-author-details{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
}

.article-author-img{
    width: 6vw;
    height: 6vw;
    
}

.article-author-name{
    font-family: 'inika';
    font-weight: 700;
    font-size: 3vw;
}

.article-author-bio{
    font-family: 'inika';
    font-weight: 200;
    font-size: 1.2vw;
}



