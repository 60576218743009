.login{
    /* background: rgb(102,181,12);
background: linear-gradient(90deg, rgba(102,181,12,1) 0%, rgba(255,239,70,1) 35%, rgba(199,117,230,1) 100%); */
/* height: 120vh; */
margin-bottom: 50px;
}


.login-navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    width: 90%;
    /* background-color: red; */
    margin: auto;
}

.logo{
    
    width: 167px;
    height: 99px;
    position: relative;
    top: 10px;
    cursor: pointer;
}







.login-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border-bottom: 0.5px solid black; */
    
    margin-top: 60px; 
    /* gap: 30em; */
}


.login-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    border-radius: 25px;
    width: 550px;
    height: 763px;
    border: 5px solid black;
    box-shadow: 8px 10px black;
    /* top: 0px; */
    
}

.login-box-wrapper{
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* background-color: black; */
}



.login-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.login-title{
    font-size: 90px;
    font-family: 'poppins';
    font-weight: 800;
}

.login-subtitle{
    font-size: 30px;
    font-family: 'metrophobic';
    /* font-family: 'poppins'; */
    font-weight: 400;
}

.login-form{
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
}

.login-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}



.form-input{
    border: 2.5px solid black;
    width: 90%;
    height: 66px;
    border-radius: 12px;
    padding: 10px;
    outline: none;
    font-size: 20px;
    margin: 10px;
}


.forgot a{
    text-decoration: underline;
    cursor: pointer;
    margin: 10px;
    
}

.forgot{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: flex-start;
}
.form-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'poppins';
    transition: 0.2s ease-in-out;
    width: min-content;
    height: 50px;
    border-radius: 22px;
    border: 2px solid black;
    font-weight: 800;
    background-color: var(--purple);
    box-shadow: 1px 5px black;
    font-size: 30px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.form-btn:hover{
    box-shadow: 0px 1px black;
}

.sign-btn{
    text-decoration: none;
    color: black;
}

.loginBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    /* background-color: red; */
    width: 90%;
}



@media (max-width: 590px) {
    .login-box{
        width: 400px;
    }

    .login-title{
        font-size: 70px;
    }

    .login-subtitle{
        font-size: 25px;
    }
  }

@media (max-width: 468px) {
    .login-box{
        width: 350px;
    }

    .login-title{
        font-size: 60px;
    }

    .login-subtitle{
        font-size: 20px;
    }
  }


