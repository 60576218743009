.signup{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right-heading{
    display: none;
    font-size: 60px;
    font-family: 'poppins';
}

.left
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid black;
    height: 100vh;
    background-color: white;
    width: 70%;
}

.left-text
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.signup-title{
    font-size: 80px;
    font-weight: 1000;
    font-family: 'poppins';
}

.signup-subtitle{
    font-size: 45px;
    font-weight: 200;
    font-family: 'metrophobic';
}

.left-img{
    width: 700px;
    height: 700px;
    transform: scaleX(-1);
}

.right{
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100vh;
    width: 60%;
}

.right-title{
    font-family: 'poppins';
    font-weight: 500;
    font-size: 50px;
}

.signup-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 80%;
}

.sign-to-log{
    text-decoration: underline;
}

.already{
    font-family: 'poppins';
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    margin-top: 30px;
    text-decoration: none;
    color: black;
}

.signup-form-input{
    border: 2.5px solid black;
    width: 100%;
    height: 66px;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    outline: none;
    font-size: 20px;
    margin: 10px;
}

.right-btn{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'poppins';
    transition: 0.2s ease-in-out;
    width: 400px;
    height: 50px;
    border-radius: 22px;
    border: 2px solid black;
    font-weight: 800;
    background-color: var(--purple);
    box-shadow: 1px 5px black;
    font-size: 30px;
}

.form-btn:hover{
    box-shadow: 0px 1px black;
}

.form-error{
    color: red;
    font-family: 'poppins';
    font-weight: 600;
    margin-left: 10px;
}

@media (max-width: 1163px) {
    .left{
        width: 50%;
    }
  }
@media (max-width: 1193px) {
    .left{
        width: 45%;
    }

    .left-img{
        width: 500px;
        height: 500px;
    }
  }
@media (max-width: 1057px) {
    .left{
        width: 30%;
    }

    .left-img-wrapper{
        display: none;
    }

    .signup-title{
        writing-mode: vertical-lr;
 text-orientation: upright;
    }

    .signup-subtitle{
        display: none;
    }

    .signup-form{
        width: 100%;
    }

    /* .right-heading{
        display: initial;
    }

    .right-title{
        display: none;
    } */
  }

@media (max-width: 583px) {
    .left{
        width: 30%;
    }

    .left-img-wrapper{
        display: none;
    }

    .signup-title{
        writing-mode: vertical-lr;
 text-orientation: upright;
    }

    .signup-subtitle{
        display: none;
    }

    .signup-form{
        width: 100%;
    }

    .right{
        width: 100%;
    }

    .left{
        display: none;
    }

    .signup{
        align-items: center;
        justify-content: center;
    }

    .right-heading{
        display: initial;
        font-weight: 1000;
    }

    .right-title{
        display: none;
    }

    .already{
        font-size: 17px;
    }
  }




