.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "poppins";
  transition: 0.2s ease-in-out;
}

.primaryBtn {
  width: 400px;
  width: 100%;
  height: 50px;
  border-radius: 22px;
  border: 2px solid black;
  font-weight: 800;
  background-color: var(--purple);
  box-shadow: 1px 5px black;
  font-size: 30px;
}

.primaryBtn:hover {
  box-shadow: 0px 1px black;
}

.secondaryBtn {
  width: 173px;
  height: 40px;
  border-radius: 22px;
  border: 2px solid black;
  font-weight: 700;
  background-color: white;
  box-shadow: 1.5px 4px black;
  font-size: 20px;
}

.secondaryBtn:hover {
  box-shadow: 0px 1px black;
}

.tertiaryBtn {
  width: 130px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid black;
  font-weight: 800;
  font-family: "metrophobic";
  background-color: #dff979;
  box-shadow: 1px 3px black;
}

.tertiaryBtn:hover {
  box-shadow: 0px 1px black;
}

.publishBtn,
.cancelBtn {
  width: 200px;
  height: 60px;
  border: 2px solid black;
  font-weight: 700;
  font-family: "poppins";
  font-size: 30px;
  background-color: #d3f840;
  box-shadow: 4px 6px black;
}

.cancelBtn {
  background-color: #f34444;
}

.publishBtn:hover,
.cancelBtn:hover {
  box-shadow: 0px 1px black;
}
