.bookmarks-page{
    /* background-color: red; */
    /* height: 85vh; */
    margin-top: 20px;
    margin-left: 100px;
    margin-right: 50px;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: space-evenly;
}

.bookmark-posts{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    

}

.bookmark-post{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.bookmark-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}



