@import "./styles/variables.css";
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: black;
 
}

body{
  background-color: var(--background);
}
