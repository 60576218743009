.header{
    background-color: var(--purple);
    border: 1px solid black;
    border-radius: 50px;
    text-align: center;
    width: 50%;
    padding: 15px;
}

.header-text{
    font-family: 'metrophobic';
    font-size: 40px;
    font-weight: 500;
}