.edit-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
    gap: 50px;
}

.edit-page-heading{

    width: 80%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
}

.edit-profile {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
  z-index: 1000;
  gap: 80px;
  border: 4px solid black;

}

.browse-container{
    display: flex;
    align-items: center;
    justify-content: center;
  margin-top: 20px;

    /* margin-top: 100px; */
}

.edit-img-container {
  background-color: #e0dfdf;
  width: 200px;
  height: 200px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.edit-img-container h1 {
  font-family: "metrophobic";
  font-size: 1.2vw;
}

.browse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-info-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.edit-buttons {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin: auto;
  gap: 20px;
  margin-bottom: 50px;
}
