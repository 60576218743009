.overlap{
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(3, 1fr);
    width: 60%;
    /* border: 2px solid black; */

}

.overlap-label-container{
    border: 1px solid black;
    background-color: var(--purple);
    min-width: 20%;
    max-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1/span 3;
    grid-column: 1 / span 4;
    z-index: 10;
    border-radius: 25px;
    max-height: 60px;
}

.overlap-label{
    font-family: 'inika';
    font-size: 1.2vw;
}

.overlap-input-container{
    grid-column: 1 / span 4;
    grid-row: 3 / span 6;
    margin-left: 20px;

}

.overlap-input{
    outline: none;
    width: 100%;
    min-height: 60px;
    /* padding: 20px; */
    font-size: 20px;
    border: 2px solid black;
    font-family: 'inika';
}

.preview{
    height: 1000px;
    width: 1000px;
    border: 2px solid black;
}


