.article-card{
    display: grid;
    grid-template-rows: repeat(6,1fr);
    grid-template-columns: repeat(5,1fr);
    width: 80%;
}

.article-heading-container{
    border: 2px solid black;
    background-color: var(--purple);
    /* min-width: 20%;
    max-width: 20%; */
    display: inline-block;
    grid-row: 1/span 1;
    grid-column: 1 / span 4;
    z-index: 10;
    padding: 15px;
    border-radius: 50px;
    text-align: center;
    /* width: min-content; */
    max-height: 60px;
}

.article-heading{
    font-family: 'poppins';
    font-weight: 500;
    font-size: 1.2vw;
}

.article-info-container{
    grid-column: 1/ span 6;
    grid-row: 1 / span 6;
    margin-left: 60px;
    margin-top: 30px;
    background-color: white;
    border: 2px solid black;
    box-shadow: 4px 5px black;
    /* width: 100%; */
}

.article-info-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px;
    
}

.article-date-container{
    display: flex;
    justify-content: flex-end;
}

.article-date{
    font-family: 'inika';
    font-size: 1vw;
    font-weight: 200;
}

.article-image-container{
    display: flex;
    justify-content: center;
}



.article-image{
    width: 90%;
    height: 300px;
    border: 2px solid black;
}

.article-time-container{
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}



.article-time{
    font-family: 'inika';
    font-size: 1.1vw;
    font-weight: 200;
}

.article-bookmark{
    cursor: pointer;
}

.article-subtitle-container{
    width: 90%;
    margin: auto;
    text-align: center;
}

.article-subtitle{
    font-family: 'poppins';
    font-size: 1.4vw;
    font-weight: 500;
}

.article-content-container{
    border: 2px solid black;
    padding: 20px;
}

.article-content{
    font-family: 'metrophobic';
    font-weight: 600;
}

