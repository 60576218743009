.post-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin-bottom: 30px;
}


.post-author{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
    margin-bottom: 10px;

}

.author-name{
    font-family: 'inika';
    font-size: 20px;
    font-weight: 200;
}

.author-img{
    width: 40px;
    height: 40px;
}

.post{
    display: flex;
    width: 100%;
    
}

.post-text{
    display: flex;
    flex-direction: column;
    border: 3px solid black;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 8px black;
    min-width: 700px;
    max-width: 900px;
}

.post-title{
    font-family: 'inika';
    font-weight: 800;
    
}
.post-subtitle{
    font-family: 'inika';
    font-weight: 200;
}

.post-info-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: red; */
    width: 95%;
}

.post-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: red; */
    width: 50%;
}

.post-date, .post-time, .post-category{
    font-family: 'inika';
    font-weight: 100;
    font-size: 15px;
}

.post-category-container{
    border: 1.5px solid black;
    /* border-radius: 50px; */
    box-shadow: 1px 1px black;
    padding: 2px;
    background-color: var(--purple);
}

.post-category{
    font-size: 0.7vw;
}

.bookmark{
    cursor: pointer;
}
.post-thumbnail{
    width: 225px;
    height: 147px;
    border: 3px solid black;
}

.post-thumbnail-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}


